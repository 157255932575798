/* .App {
  font-family: sans-serif;
  text-align: center;
} */

#FilterMenuContainer {
  position: fixed;
  /* height: 605px; */
  width: 290px;
  background-color: rgba(241, 241, 241, 0.8);
  border: solid;
  border-width: 0.5px;
  z-index: 1000;
  transition: 0.5s;
}

#FilterAbsolute {
  position: relative;
  height: 100%;
  width: 100%;
}

#ArrowContainer {
  height: 50px;
  width: 30px;
  background-color: rgba(241, 241, 241, 0.8);
  position: absolute;
  left: 100%;
  top: -1.5%;
  text-align: center;
}

#ArrowImage {
  position: absolute;
  top: 10px;
  left: 2px;
}

#FilterMenu {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

#MenuTitleContainer {
  text-align: center;
}

#MenuTitleText {
  margin-top: 10px;
  font-weight: bolder;
}

.FilterMenuBreaks {
  width: 85%;
  opacity: 0.7;
}

#GraphSummaryText {
  font-size: 12.5px;
}

#LegendContainer {
  margin-top: 15px;
  width: 100%;
}

#LegendLabels {
  margin-top: -60px;
}

#NodeLabel {
  font-size: 100px;
  margin-left: 0px;
  text-align: left;
}

.LegendLabelText {
  margin-left: 8px;
}

#SearchBarContainer {
  margin-left: 10px;
  padding-bottom: 10px;
}
